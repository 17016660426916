import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AOS from 'aos'
import Helmet from 'react-helmet'

const ScrollAnimationWrapper = ({ refreshDuration, children, ...params }) => {
  useEffect(() => {
    AOS.init(params)

    const timeout = setTimeout(() => {
      AOS.refresh()
    }, refreshDuration)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Helmet link={[{ rel: 'stylesheet', href: 'https://unpkg.com/aos@next/dist/aos.css' }]} />
      {children}
    </>
  )
}

ScrollAnimationWrapper.defaultProps = {
  easing: 'ease-out-sine',
  duration: 1000,
  refreshDuration: 1000,
  anchorPlacement: 'top-bottom'
}

ScrollAnimationWrapper.propTypes = {
  easing: PropTypes.string,
  duration: PropTypes.number,
  refreshDuration: PropTypes.number,
  anchorPlacement: PropTypes.string
}

export default ScrollAnimationWrapper
