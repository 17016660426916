import React from 'react'
import StyledFadeIn from './style'

const FadeIn = ({
  anchor, anchorElement, className, children, delay, duration
}) => (
  <StyledFadeIn className={className} data-aos="fade-in" data-aos-delay={delay} data-aos-anchor={anchorElement} data-aos-anchor-placement={anchor} data-aos-duration={duration}>
    {children}
  </StyledFadeIn>
)

export default FadeIn
