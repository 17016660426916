import styled from 'styled-components'

const StyledFadeIn = styled.div`
  [data-aos="fade-in"] {
    opacity: 0;
    transition-property: opacity;

    &.aos-animate {
      opacity: 1;
    }
  }
`

export default StyledFadeIn
